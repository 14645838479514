import { AuthHelper } from 'utils/authHelper.util';
import { IStrapiEntry } from 'models/strapi/strapiEntry.model';
import { IStrapiNeed } from 'models/strapi/strapiNeeds.model';

export class NeedsApiService {
  public static async getLastNeed(): Promise<IStrapiEntry<IStrapiNeed>> {
    return await AuthHelper.fetch('/api/v1/needs/last', {
      method: 'GET',
    });
  }

  public static async getNeedsCount(): Promise<number | undefined> {
    return await AuthHelper.fetch('/api/v1/needs/count', {
      method: 'GET',
    });
  }

  public static async getNeedFile(fileId: number): Promise<Blob> {
    return await AuthHelper.fetch(`/api/v1/needs/files/${fileId}`, {
      method: 'GET',
    });
  }
}
